import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { MdOutlineEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useMainStore from '../../../store/useMainStore';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import PropTypes from 'prop-types';
const SurveyAdminTemplate = ({wwid, isAdmin}) => {
    const [selectedTemplate, setSelectedTemplate] = useState({id:null, });
    const {surveyTemplateAccessList, getSurveyTemplateAccessList} = useMainStore();
    useEffect(() => {
        getSurveyTemplateAccessList(wwid, isAdmin);
    }, []);
    const haveAccess = isAdmin || surveyTemplateAccessList?.data?.length > 0;
    return (
        <div>
            {surveyTemplateAccessList.fetched ? 
                <div className='admin-survey' >
                    <div className='admin-survey-top-btns'>
                        <Link to={'/survey-admin-form/new'} >
                            <button className='btn btn-green'>Create Survey Template</button>
                        </Link>
                        { haveAccess && <Link to={'/survey-responses'} >
                            <button className='btn btn-blue'>Response Viewer</button>
                        </Link>}
                    </div>
                    <div className='admin-survey-top-btns'>
                        <div style={{width:300}} data-testid='admin-template-select'>
                            <p style={{marginBottom:'5px'}}>Survey Template</p>
                            <Select
                                options={surveyTemplateAccessList.data}
                                onChange={val => setSelectedTemplate({id:val?.survey_template_id, name:val?.survey_template_name})}
                                getOptionLabel={option => option.survey_template_name}
                                getOptionValue={option => option.survey_template_id}
                                aria-label={'select-template'}
                                isDisabled={!haveAccess}
                            />
                        </div>
                        {selectedTemplate.id && <Link to={'/survey-admin-form/' + selectedTemplate.id} >
                            <button className='btn btn-blue' style={{minWidth:80, height:37}}> <MdOutlineEdit size={20}/>Edit</button>
                        </Link>}
                    </div>
                
                </div> : <LoadingAnimation/>}
        </div>
    );
};

SurveyAdminTemplate.propTypes = {
    wwid:PropTypes.string,
    isAdmin:PropTypes.bool
};

export default SurveyAdminTemplate;