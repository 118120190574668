import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getRequest } from '../../../services/axiosClient';
import { getBaseUrl } from '../../../util/getBaseUrl';
import SurveyAdminFormHeader from './SurveyAdminFormHeader';
import useMainStore from '../../../store/useMainStore';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import CustomPopup from '../../../components/common/customPopup/CustomPopup';
import { ToastContainer, toast } from 'react-toastify';
import '../FormComponents/formComponents.css';
import { getDetailErrorMsg } from '../../../util/functions';
import QuestionForm from './Question/QuestionForm';
import ProductForm from './Product/ProductForm';
import QuestionsTable from './Question/QuestionTable';
import ProductTable from './Product/ProductTable';
import { HiMiniHome } from 'react-icons/hi2';
import PropTypes from 'prop-types';

const baseUrl = getBaseUrl();
const productUrl = baseUrl + '/api/datasrcs/1/schemas/survey/tables/product/records?display-value=true&survey_template_id=';
const questionUrl = baseUrl + '/api/datasrcs/1/schemas/survey/tables/question/records?display-value=true&survey_template_id=';

const SurveyAdminForm = ({wwid, isAdmin}) => {
    const params =useParams();
    const [template, setTemplate] = useState({data:{}});
    const getDefaultProduct = () => ({
        workbook_id: null,
        name: '',
        email_notif: '',
        active_flag: true,
        survey_template_id: template?.data?.id || null,
    });
    
    const getDefaultQuestion = () => ({
        question_text: '',
        active_flag: true,
        mandatory_flag: true,
        question_type_id: null,
        question_order: '',
        survey_template_id: template?.data?.id || null,
    });
    const {getSurveyTemplates, surveyTemplates} = useMainStore();
    const [loading, setLoading] = useState({template:true});
    const [selectedProduct, setSelectedProduct] = useState({active:false, data:getDefaultProduct() });
    const [selectedQuestion, setSelectedQuestion] = useState({active:false, data:getDefaultQuestion() });
    
    const [products, setProducts] = useState([]);
    const [questions, setQuestions] = useState([]);
    const getQuestions = async ()=>{
        setLoading(prev => ({...prev, questions:true}));
        try {
            const resQ  = await getRequest(questionUrl + params.id);
            setQuestions(resQ.data.data.sort((a,b)=> a.id - b.id));
        } catch (error) {
            toast.error('Error with getting survey questions:' + getDetailErrorMsg(error));
        } finally {
            setLoading(prev => ({...prev, questions:false}));
        }
    }; 
    const getProducts = async () =>{
        setLoading(prev => ({...prev, products:true}));
        try {
            const res  = await getRequest(productUrl + params.id);
            setProducts(res.data.data.sort((a,b)=> a.id - b.id));
        } catch (error) {
            toast.error('Error with getting survey products:' + getDetailErrorMsg(error));
        } finally {
            setLoading(prev => ({...prev, products:false}));
        }
    };

    const getTemplate = id => {
        setLoading(prev => ({ ...prev, template: true }));
        const temp = surveyTemplates.data.find(item => item.id === Number(id));
        if (temp) {
            setTemplate({
                data: {
                    id: temp.id,
                    name: temp.name,
                    owner_wwid: temp.owner_wwid,
                    active_flag: temp.active_flag,
                },
                defOwner:temp.owner_wwid
                    ? { wwid: temp.owner_wwid, display_name: temp.owner_wwid_dv }
                    : null,
            });
        } else {
            toast.error('Survey template not found.');
            setTemplate({ data: {},defOwner:null });
        }
        setLoading(prev => ({ ...prev, template: false }));
    };

    useEffect(() => {
        if(!surveyTemplates.fetched && !isNaN(params.id)){
            getSurveyTemplates();
        } 
        if(!isNaN(params.id) && surveyTemplates.fetched){
            getTemplate(params.id);
            getProducts();
            getQuestions();
        }
        
        if(params.id === 'new'){
            setTemplate({ data:{name:'', owner_wwid:null, active_flag:true}});
            setLoading({template:false, products:false, questions:false});
        }
        
    }, [surveyTemplates.fetched, params.id]);

    const handleEdit = (data, question = false) =>{
        if(!question){
            setSelectedProduct({active:true, data, title:data.id ? `Edit ${data?.name}` : 'Create Product'});
        } else {
            setSelectedQuestion({active:true, data, title:data?.id? 'Edit Question' : 'Create Question'});
        }
    };

    const closePopup = () => {
        setSelectedProduct({ active: false, data: getDefaultProduct() });
        setSelectedQuestion({ active: false, data: getDefaultQuestion() });
    };

    if(loading.template){
        return <LoadingAnimation/>;
    }

    return (
        <div className='survey-page-container'>
            <ToastContainer theme='light' position='bottom-right' />
            <Link className='btn btn-blue' to='/survey' style={{width:190, marginBottom:10}}> <HiMiniHome size={22}/>Survey Home Page</Link>
            <SurveyAdminFormHeader templateData={template} wwid={wwid} isAdmin={isAdmin}/>
            {template?.data?.id && 
                <div className='survey-page'>
                    {loading.products ? <LoadingAnimation type={true}/> 
                        : <ProductTable products={products} defValue={getDefaultProduct()} handleEdit={handleEdit}/>}
                    {loading.questions ? <LoadingAnimation type={true}/> 
                        : <QuestionsTable questions={questions} defValue={getDefaultQuestion()} handleEdit={handleEdit}/>}
                </div>
            }

            {selectedProduct.active && <CustomPopup onClose={closePopup} tableName={selectedProduct.title} formType={'other'} >
                <ProductForm product={selectedProduct.data} closePopup={closePopup} newRecord={getDefaultProduct()} updateTable={getProducts}/>
            </CustomPopup>}

            {selectedQuestion.active && <CustomPopup onClose={closePopup} tableName={selectedQuestion.title} formType={'other'} surveyTemplateId={template.data.id} >
                <QuestionForm question={selectedQuestion.data} closePopup={closePopup} newRecord={getDefaultQuestion()} updateTable={getQuestions}/>
            </CustomPopup>}
        </div>
    );
};

SurveyAdminForm.propTypes ={
    wwid: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default SurveyAdminForm;