import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SurveyQuestionAnswerList from './QuestionAnswerList';
import useMainStore from '../../../../store/useMainStore';
import LoadingAnimation from '../../../../components/common/loadingAnimation/LoadingAnimation';
import { postRequest, putRequest } from '../../../../services/axiosClient';
import { getBaseUrl } from '../../../../util/getBaseUrl';
import { getDetailErrorMsg } from '../../../../util/functions';
import TextInput from '../../FormComponents/TextInput';
import CheckboxInput from '../../FormComponents/CheckboxInput';
import FormSubmitButtons from '../../FormComponents/FormSubmitButtons';

const url  = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/question/records';
const answersUrl = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/question_answer/upload';
const getMessage = (id, loading) =>{
    if(loading)
        return id ? 'Updating' : 'Creating';
    return `${id ? 'Updated' : 'Created'  } Successfully!`;
};
const checkType = type => (type === 'Single Select') || (type === 'Multi Select');


const QuestionForm = ({question, closePopup, newRecord, updateTable}) => {
    const{questionType, getQuestionTypes} = useMainStore();
    const [originalData, setOriginalData] = useState(question);
    const [questionData, setQuestionData] = useState(question);
    const [isEdited, setIsEdited] = useState({answers:false, form:false});
    const [saving, setSaving] = useState(false);
    const [showAnswerList, setShowAnswerList] = useState(false);
    const [answers, setAnswers] = useState([
        {name: '', question_id: originalData?.id, active_flag:true},
        {name: '', question_id:originalData?.id, active_flag:true}
    ]);

    useEffect(()=>{
        if(!questionType.fetched)
            getQuestionTypes();

        setShowAnswerList(checkType(questionData.question_type_id_dv));
    },[questionType.fetched]);
    const resetForm = ()=>{
        setOriginalData(newRecord);
        setQuestionData(newRecord);
        setAnswers([
            {name: '', question_id: originalData?.id, active_flag:true},
            {name: '', question_id:originalData?.id, active_flag:true}
        ]);
    };
 
    const callback = {};
    const handleSubmit = async (e, addNew, close) =>{
        e.preventDefault();
        if (!e.target.form.checkValidity()) {
            e.target.form.reportValidity();
            return;
        }

        if(!isEdited.form && !isEdited.answers)
            return;
        
        if (answers.filter(item => item.active_flag).length < 2) {
            toast.error('You must have at least 2 active answers before saving.');
            return;
        }


        const operation = getMessage(originalData.id, true);
        const toastId = toast.loading(`${operation} Question`);
        setSaving(true);

        try {
            let response;
            if (originalData.id) { 
                if(isEdited.form){
                    response = await putRequest(`${url}/${originalData.id}`, questionData);
                }
            } else {
                response = await postRequest(url, questionData);
            }
            const record = response?.data?.data[0] || questionData;
            const newData = {...questionData, id: record?.id};
                
            if(isEdited.answers){
                const res = await postRequest(answersUrl, answers.map(item=>{
                    return {...item, question_id:newData.id};
                }));
                const newAnswers = answers.map((item, index) => {
                    return {...item, id:res.data.data[index].recordId};
                });
                setAnswers(newAnswers);
            }
            setOriginalData(newData);
            setQuestionData(newData);
            
            setIsEdited({form:false, answers:false});
            if(addNew){resetForm();}
            updateTable();
            
            toast.update(toastId, {
                render: getMessage(originalData.id, false),
                type: 'success',  isLoading: false, autoClose: 6000, closeButton: true
            });
            if(close){closePopup();}
        } catch (error) {
            toast.update(toastId, { 
                render: `Error with question update: ${getDetailErrorMsg(error)}`,
                type: 'error', isLoading: false, autoClose: 6000, closeButton: true
            }); 
        } finally{
            setSaving(false);
            
        }

    };

    const handleFormChange = (value, field, type)=>{
        if(type){
            setShowAnswerList(checkType(type));
        }
        setQuestionData(prev => ({...prev, [field]:value}));
        setIsEdited(prev => ({...prev, form:true}));
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className='survey-admin-form-wrap' style={{height:'auto'}}>
                <div>
                    <div>

                        <TextInput
                            label="Question Text"
                            name="question_text"
                            value={questionData.question_text || ''}
                            onChange={handleFormChange}
                            placeholder="Question Text"
                            required
                            type='textarea'
                        />
                        <p style={{fontSize:12}}>Please include the placeholder <strong>&lt;product&gt;</strong> in your question where you want the product name to appear.</p> 
                    </div>
                    
                    <div style={{display:'flex', alignItems:'center', gap:'3rem', marginTop:'2rem' }}>
                        <TextInput
                            label="Question Order"
                            name="question_order"
                            value={questionData.question_order || ''}
                            onChange={handleFormChange}
                            placeholder="Question Order"
                            type='number'
                        />
                        <CheckboxInput  
                            label="Active Flag"
                            name="active_flag"
                            checked={questionData.active_flag || false} 
                            onChange={handleFormChange}
                        />
                        <CheckboxInput  
                            label="Mandatory Flag"
                            name="mandatory_flag"
                            checked={questionData.mandatory_flag || false} 
                            onChange={handleFormChange}
                        />
                    </div>
                </div> 
                <div className='admin-form-select-cont'>
                   
                    <div style={{width:300}} data-testid='admin-template-select'>
                        <p style={{marginBottom:'5px'}} className='custom-form-label'>Question Type <span style={{ color: 'red' }}>*</span></p>
                        {questionType.fetched && !saving ? <Select
                            options={questionType.data}
                            onChange={val => handleFormChange(val.id, 'question_type_id', val.name)}
                            getOptionLabel={option => `${option.id} - ${option.name}`}
                            getOptionValue={option => option.id}
                            aria-label={'select-question'}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            required
                            defaultValue={questionType.data.filter(item=> item.id === questionData.question_type_id)}
                        />: <div className='rf-select-placeholder-div'> <LoadingAnimation type={true}/></div>}
                    </div>
                </div>
                {showAnswerList && (
                    <SurveyQuestionAnswerList
                        setIsEdited={setIsEdited}
                        questionId={originalData?.id || null }
                        callback={callback}
                        answers={answers}
                        setAnswers={setAnswers}
                        saving={saving}
                    />
                )} 
                <FormSubmitButtons isSaveDisabled={() => !isEdited.form && !isEdited.answers} handleSubmit={handleSubmit} saving={saving}/>
            </form>
        </div>
    );
};

QuestionForm.propTypes = {
    question: PropTypes.object.isRequired,
    closePopup:PropTypes.func.isRequired,
    newRecord:PropTypes.object.isRequired,
    updateTable:PropTypes.func.isRequired
};

export default QuestionForm;