
import { backendURL } from '../config/constants';
import { postRequest } from '../services/axiosClient';
import { formatDateTime } from '../util/functions';
import { getBaseUrl } from '../util/getBaseUrl';


export default function logPageView(userEmail, pageview) {
    //url to connect to backend api
    const detailDataUrl = `${getBaseUrl()}${backendURL().detailsTableData}`;

    const startTime = new Date();
    const startTimeFormatted= formatDateTime(startTime);
    const fullPath = pageview.pathname + pageview.search;
    //only sends call if user data is loaded
    if (userEmail &&( fullPath.length  < 200)) {
        //this is the object sent to the backend that contains log data.
        const logData = {
            user_name: userEmail?.split('@')[0],
            pathname: fullPath,
            hostname: window.location.hostname,
            visit_timestamp: startTimeFormatted
        };
        const sendLoggedUrl =async ()=>{
            await postRequest(`${detailDataUrl}tspmcfg/tables/page_view_log/records`, logData);
        };
        sendLoggedUrl();
    }
}
