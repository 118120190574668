import React from 'react';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';
import PropTypes from 'prop-types';
import { commonColumns } from './columnConfig';


const LogPage = ({ log }) => {

    const columnConfig = commonColumns();


    return (
        <div className='view-log-page'>
            <DataTable
                tableData={log}
                columnConfig={columnConfig}
                disableDownload={false}
                showFooter={false}
                themeColor="#102b3f"
                buttonColor="#102b3f"
            />
        </div>
    );
};


LogPage.propTypes = {
    log: PropTypes.any.isRequired,
};

export default LogPage;
