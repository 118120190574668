import React,{useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tableau } from '@jauj/reactpkg-tsis-tableau';
import { getRequest } from '../../../services/axiosClient';
import { getBaseUrl } from '../../../util/getBaseUrl';
import { checkDefault, separateFiltersAndParameters } from '../../../util/functions';
import Loading from '../../../components/common/loading';

const dashboardFilterParams = `${getBaseUrl()}/api/datasrcs/1/folder/dataviz/customQuery/get_mylink_filters_params?filters=`;
// Iframe load time to show the loading
const iframeLoadTimeout = 1000;

// Tableau configurations
const options = {
    height: 2300,
    width: '100%',
    toolbar: 'top',
};

const DashboardTableau = ({handleTabSwitch, refObj, mylink, urlFilters, sideMenuData, dashboardViews, productId, workbookId, workbookViewId, respData})=>{
    const [filterParams, setFilterParams] = useState({ status: false, parameters: [], filters: [], mylink: null });
    
    //grabs initial workbook data and sets iframe load to false.
    useEffect(() => {
        setFilterParams({ status: false, mylink: null });

        if(mylink) {
            getMyLinkParams(mylink);
        } else if(urlFilters) {
            getUrlFilters(urlFilters);
        } else {
            const myLinkDefault = checkDefault(dashboardViews, `${productId}/${workbookId}/${workbookViewId}`);
            if(myLinkDefault) {
                getMyLinkParams(myLinkDefault);
            } else { 
                setTimeout(() => setFilterParams({ status: true, filters: [], parameters: [], mylink: null }), iframeLoadTimeout);
            }
        }

    }, [mylink, urlFilters]);

    const getMyLinkParams = async myLinkId => {
        try {
            // Send an HTTP request to get the filter parameters
            const res = await getRequest(dashboardFilterParams + myLinkId);
    
            // Initialize filters and parameters
            let initialFilters = [];
            let initialParameters = [];
    
            // If there is data available, process each item
            if (res.data.rowCount > 0 && Array.isArray(res.data.data)) {
                // Assign new values to the variables
                ({ initialFilters, initialParameters } = separateFiltersAndParameters(res.data.data));
            }
    
            // Update the component state with the fetched filters and parameters
            setFilterParams({ status: true, filters: initialFilters, parameters: initialParameters, mylink: true });
        } catch (error) {
            // Handle any errors that occur during the HTTP request
            refObj.errorInCall(error);
        }
    };

    const getUrlFilters = async filterObj => { 
        const initialFilters = [];
        Object.entries(filterObj).forEach( ([key, value]) => {
            const valueArr = value.split(',');
            initialFilters.push({
                field: key,
                values: valueArr
            });
        });
        setFilterParams({ status: true, filters: initialFilters, parameters: [], mylink: null });
    };

    return (
        <div className={`tableau-report ${sideMenuData.length <= 1 ? 'full-width' : ''}`}>
            {!filterParams.status ? <Loading isLoading={true} /> :
                <>
                    <Tableau
                        reportUrl={respData.url + '&:jsdebug=n'}
                        options={options}
                        initialFilters={filterParams.filters}
                        initialParameters={filterParams.parameters}
                        onTabSwitched={handleTabSwitch}
                    />
                </>
            }
        </div>
    );
};

DashboardTableau.propTypes = {
    handleTabSwitch : PropTypes.func,
    refObj: PropTypes.object,
    mylink: PropTypes.any,
    urlFilters: PropTypes.object,
    sideMenuData: PropTypes.array,
    dashboardViews: PropTypes.array,
    productId: PropTypes.string,
    workbookId : PropTypes.string,
    workbookViewId: PropTypes.string,
    respData: PropTypes.object
};

export default React.memo(DashboardTableau);