
export const getItemsInCurrentPath = (data, path) => {
    for (let part of path) {
        const nextFolder = data.find(folder => folder.name === part && folder.isDir);
        if (nextFolder && nextFolder.children) {
            data = nextFolder.children;
        } else {
            return []; 
        }
    }
    return data.sort(sortItems); 
};

const sortItems = (a, b) => {
    // Prioritize directories over files
    if (a.isDir && !b.isDir) {
        return -1;
    }
    if (!a.isDir && b.isDir) {
        return 1;
    }
    // If both are files, sort by modified time (newest first)
    if (!a.isDir && !b.isDir) {
        const dateA = new Date(a.modifiedTime);
        const dateB = new Date(b.modifiedTime);
        return dateB - dateA;
    }
    // If both are directories, sort alphabetically
    return a.name.localeCompare(b.name);
};


export const formatSize = sizeBytes => {
    const toKbyte = 1024;
    const fixedTwo = 2;
    return sizeBytes > 0 ? `${(sizeBytes / toKbyte).toFixed(fixedTwo)} KB` : '-';
};

export const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};