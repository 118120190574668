import { create } from 'zustand';
import { getBaseUrl } from '../util/getBaseUrl';
import { getRequest } from '../services/axiosClient';
import { getErrorMsg } from '../util/functions';
import { toast } from 'react-toastify';
 
const useMainStore = create(set => (
    {
        title: '',
        setTitle: data => set({title: data}),
        isAuthorized: false,
        setIsAuthorized:data => set({isAuthorized: data}),
        surveyCheck:false,
        setSurveyCheck: data => set({surveyCheck:data}),
        surveyTemplates:{fetched:false, data:[]},
        getSurveyTemplates: async () => {
            set({surveyTemplates:{fetched:false, data:[]}});
            try {
                const url = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/survey_template/records?display-value=true';
                const response = await getRequest(url);
                set({surveyTemplates:{fetched:true, data:response.data.data}});
            } catch (error) {
                toast.error(`Error with getting survey templates: ${getErrorMsg(error)}`);
            }
        },
        workbooks:{fetched:false, data:[]},
        getWorkbooks: async () => {
            try {
                const url = getBaseUrl() + '/api/datasrcs/1/schemas/dataviz/tables/workbook/records';
                const response = await getRequest(url);
                response.data.data.sort((a,b) => a.id - b.id);
                set({workbooks:{fetched:true, data:response.data.data}});
            } catch (error) {
                toast.error(`Error with getting workbooks: ${getErrorMsg(error)}`);
            }
        },
        questionType:{fetched:false, data:[]},
        getQuestionTypes: async () => {
            try {
                const url = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/question_type/records';
                const response = await getRequest(url);
                response.data.data.sort((a,b) => a.id - b.id);
                set({questionType:{fetched:true, data:response.data.data}});
            } catch (error) {
                toast.error(`Error with getting question types: ${getErrorMsg(error)}`);
            }
        },
        surveyResponses:{fetched:false, data:[]},
        getSurveyResponses: async wwid => {
            set({surveyResponses:{fetched:false, data:[]}});
            const url = getBaseUrl() + '/api/datasrcs/1/folder/survey/customQuery/get_user_survey_responses?filters=';
            try {
                const response = await getRequest(url + wwid);
                response.data.data.sort((a, b) => b.response_instance_id - a.response_instance_id);
                set({surveyResponses:{fetched:true, data:response.data.data}});
            } catch (error) {
                toast.error(`Error with getting survey responses: ${getErrorMsg(error)}`);
            }
        },
        surveyTemplateAccessList:{fetched:false, data:[], loading:false},
        getSurveyTemplateAccessList: async (wwid, isAdmin) => {
            set(state => ({
                surveyTemplateAccessList: {
                    ...state.surveyTemplateAccessList,
                    loading: true
                }
            }));
            const baseUrl = getBaseUrl();
            const url = isAdmin
                ? `${baseUrl}/api/datasrcs/1/folder/survey/customQuery/get_survey_templates_products_all`
                : `${baseUrl}/api/datasrcs/1/folder/survey/customQuery/get_survey_templates_products_by_wwid?filters=${wwid}`;
            try {
                const response = await getRequest(url);
                set(state => ({
                    surveyTemplateAccessList: {
                        ...state.surveyTemplateAccessList,
                        fetched: true,
                        data: response.data.data,
                        loading: false
                    }
                }));
            } catch (error) {
                toast.error(`Error with getting survey template access list: ${getErrorMsg(error)}`);
                set(state => ({
                    surveyTemplateAccessList: {
                        ...state.surveyTemplateAccessList,
                        loading: false,
                        fetched: true,
                    }
                }));
            }
        },
    }
));


// An object for handling authorization outside of React components.
// This object has a function for setting the authorization status.
export const apiAuth = {
    setIsAuthorized: isAuthorized => {
        // Set the authorization status in the main store.
        useMainStore.setState({ isAuthorized });
    }
};

// Export the hook so other components can use it.
export default useMainStore;
