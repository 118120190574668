import { DataTable } from '@jauj/reactpkg-tsis-datatable';
import React from 'react';
import PropTypes from 'prop-types';
import { generateColumns } from '../commonColumns';

const QuestionsTable = ({ questions, handleEdit, defValue }) => {

    return (
        <aside>
            <div className='survey-table-header'>
                <h3>Questions</h3> <button className="btn btn-blue" style={{height:30}} onClick={()=> handleEdit(defValue, true)}>Create Question</button>
            </div>
            <DataTable
                {...{
                    tableData:questions,
                    columnConfig:generateColumns('questions', handleEdit), 
                    disableDownload:true, 
                    disableFilter:true,
                    themeColor:'#242F42',
                    disableSearch:true
                }}
            />
        </aside>
    );
};
QuestionsTable.propTypes ={
    handleEdit:PropTypes.func.isRequired,
    questions:PropTypes.array.isRequired,
    defValue:PropTypes.object.isRequired
};

export default QuestionsTable;
