import React from 'react';

import PropTypes from 'prop-types';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';

const FormSubmitButtons = ({ saving, handleSubmit, isSaveDisabled}) => {

    return saving ? (
        <div className='admin-btn-container'>
            <button type="submit" disabled className='btn btn-blue' style={{width:'160px'}}>
                Saving <LoadingAnimation type={true}/>
            </button>
        </div>
    ) : (
        <div className='admin-btn-container'>
            <button type="submit" onClick={e => handleSubmit(e, false, false)} className='btn btn-blue' disabled={isSaveDisabled()}>
                Save
            </button>
            <button type="submit" onClick={e => handleSubmit(e, true, false)} className='btn btn-green' disabled={isSaveDisabled()}>
                Save and Create Another
            </button>
            <button type="submit" onClick={e => handleSubmit(e, false, true )} className='btn btn-violet' disabled={isSaveDisabled()}>
                Save and Close
            </button>
        </div>
    );
};



FormSubmitButtons.propTypes = {
    saving: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isSaveDisabled:PropTypes.func,
};

export default FormSubmitButtons;