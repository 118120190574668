import React, {useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

const Admin = ({setTitle}) => {
    /* set title */
    useEffect(() => {
        setTitle('TS Insights & Solutions Admin Page');
    }, []);
    return (
        <Outlet/>
    );
};

Admin.propTypes={
    setTitle: PropTypes.any
};

export default Admin;