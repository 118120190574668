import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '../../../../services/axiosClient';
import { getBaseUrl } from '../../../../util/getBaseUrl';
import LoadingAnimation from '../../../../components/common/loadingAnimation/LoadingAnimation';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../../../util/functions';

const answersUrl = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/question_answer/records?question_id=';

const QuestionAnswerList = ({  setIsEdited, answers, setAnswers, saving, questionId = null}) => {
    const [loading, setLoading] = useState(false);
    const getAnswers = async id =>{
        setLoading(true);
        try {
            const res  = await getRequest(answersUrl + id);
            if(res.data.data.length > 0){
                setAnswers(res.data.data.sort((a,b)=> a.id - b.id)); 
            }
            
        } catch (error) {
            toast.error(`Error with getting answers: ${getDetailErrorMsg(error)}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(questionId){
            getAnswers(questionId);
        }
    }, [questionId]);

    const handleAddAnswer = () => {
        setAnswers(prev => ([
            ...prev,
            {  name: '', question_id: questionId, active_flag:true}
        ]));       
        setIsEdited(prev => ({...prev, answers:true}));
    };

    const handleAnswerChange = (value, index, field) => {
        setAnswers(prev => {
            const newAnswers = [...prev];
            newAnswers[index][field] = value;
            return newAnswers;
        });
        setIsEdited(prev => ({...prev, answers:true}));
    };

    const handleDeleteAnswer = index => {
        const newAnswers = answers.filter((_, i) => i !== index);
        setAnswers(newAnswers);
    };
    const maxAnswer = 10;
    return (
        <div className="answers-section">
            <h3 style={{margin:'10px', textAlign:'center'}}>Question Answers</h3>
            <div
                style={{display:'flex', alignItems:'flex-end', gap:'3rem', justifyContent:'space-between', marginBottom:'10px'}}
            >
                <div style={{fontSize:14, opacity:0.8}}><strong>Instructions:</strong> Please provide between two and ten  active answers for the question.</div>
                {(answers.length < maxAnswer) && !saving ? <button
                    type="button"
                    onClick={handleAddAnswer}
                    className="btn btn-green"
                    style={{height:30}}
                    data-testid='add-answer'
                >
                    Add Answer
                </button> : <button
                    type="button"
                    className="btn btn-green"
                    style={{height:30}}
                    disabled
                >
                    Add Answer
                </button>}
            </div>
            
            {loading ? <LoadingAnimation/> :  
                <table className='admin-answers-table'>
                    <thead>
                        <tr>
                            <th style={{width:'60px'}}>Id</th>
                            <th >Answer</th>
                            <th style={{width:'90px'}}>Active Flag</th>
                        </tr>
                    </thead>
                    <tbody>
                        {answers?.map((answer, index) => ( 
                            <tr key={index}>
                                <td>
                                    {answer.id ? answer.id : index > 1 && <span data-testid='delete-answer' onClick={()=> handleDeleteAnswer(index)} style={{color:'red',cursor:'pointer'}} >
                                        <FaTrashAlt size={22}/>
                                    </span>}
                                </td>
                                <td>
                                    <input 
                                        onChange={e=> handleAnswerChange(e.target.value, index, 'name')}  
                                        type="text"
                                        value={answer.name}
                                        className='custom-form-input'
                                        required
                                        data-testid='answer-list'
                                        disabled={saving}
                                    />
                                </td>
                                <td>
                                    <input
                                        className='custom-form-checkbox'
                                        onChange={e=> handleAnswerChange(e.target.checked, index, 'active_flag')}  
                                        type="checkbox"
                                        checked={answer.active_flag}
                                        data-testid='answer-checkbox'
                                        disabled={saving}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
        </div>
    );
};

QuestionAnswerList.propTypes = {
    setIsEdited: PropTypes.func.isRequired,
    questionId:PropTypes.number,
    answers:PropTypes.array.isRequired,
    setAnswers:PropTypes.func.isRequired,
    saving:PropTypes.bool.isRequired
};

export default QuestionAnswerList;
