import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
    label,
    name,
    value,
    onChange,
    placeholder,
    required = false,
    type = 'text',
    ...rest
}) => (
    <label className='custom-form-input-wrap'>
        {label && (
            <span className="custom-form-label">
                {label} {required && <span style={{ color: 'red' }}>*</span>}
            </span>
        )}
        {type === 'textarea' ? (
            <textarea
                name={name}
                value={value}
                onChange={e => onChange(e.target.value, name)}
                placeholder={placeholder}
                required={required}
                className="custom-form-textarea"
                data-testid={`form-${name}`}
                {...rest}
            />
        ) : (
            <input 
                type={type}
                name={name}
                value={value} 
                onChange={e => onChange(e.target.value, name)}
                placeholder={placeholder} 
                required={required}
                data-testid={`form-${name}`}
                className='custom-form-input'
                {...rest}
            />
        )}
    </label>



);

TextInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
};

export default TextInput;
