import React, { useEffect, useState } from 'react';
import { getRequest } from '../../services/axiosClient';
import { getBaseUrl } from '../../util/getBaseUrl';
import Select from 'react-select';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';
import PropTypes from 'prop-types';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import { FcSurvey } from 'react-icons/fc';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../util/functions';
import useMainStore from '../../store/useMainStore';

const url = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/survey_template/records?&active_flag=true';
const urlProduct = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/product/records?&active_flag=true';

const ActiveSurveys = ({wwid}) => {
    const [loading, setLoading] = useState(true);
    const [surveyTemplate, setSurveyTemplate] = useState(null);
    const [surveyProducts, setSurveyProducts] = useState({data:[], originalData:[], status:false});
    const [survey, setSurvey] = useState({isOpen:false, productId:null, productName:''});
    const {getSurveyResponses} = useMainStore();
    const getSurveyTemplates = async ()=>{
        try {
            const res  =  await getRequest(url);
            const resProduct  =  await getRequest(urlProduct);
            setSurveyTemplate([...res.data.data, {id: null, name: 'Show All', active_flag: true}]);
            setSurveyProducts({data:resProduct.data.data, originalData:resProduct.data.data, status:false});
        } catch (error) {
            toast.error(`Error with getting platform categories: ${getDetailErrorMsg(error)}`);
        } finally{
            setLoading(false);
        }
    };

    useEffect(()=>{
        getSurveyTemplates();
    },[]);
    const colConfig = [
        {
            accessorKey: 'id', 
            header: 'Id',
            textAlign: 'center',
            dataType: 'number',
            filterFn: 'numberFilter',
            disableFilter: true,
        },
        {
            accessorKey: 'name', 
            header: 'Product Name',
            textAlign: 'center',
            dataType: 'number',
            filterFn: 'numberFilter',
            disableFilter: true,
        },
        {
            header: 'Actions',
            textAlign: 'center',
            disableFilter: true,
            minWidth: 70,
            cell: info => (
                <div >
                    <span  onClick={()=> takeSurvey(info.row.original)} className='survey-link'> <FcSurvey size={25}/>Take Survey</span>
                </div>
            )
        }
    ];

    const handleTemplateChange = tempId =>{
        if(tempId) {
            const products  = surveyProducts.originalData.filter(item=> item.survey_template_id === tempId);
            const status = products.length > 0 ? true :false;
            setSurveyProducts(prev => {
                return {...prev, data:products, status:status}; 
            });
        } else {
            setSurveyProducts(prev => {
                return {...prev, data:prev.originalData}; 
            });
        }
        
    };
    const takeSurvey = val =>{
        setSurvey({isOpen:true, productId:val.id, productName:val.name});
    };
    const onClose =()=>{
        setSurvey({isOpen:false, productId:null, productName:''});

    };

    const onSuccess = () => {
        getSurveyResponses(wwid);
    };
    
    
    return (
        <div className='survey-container'>
            <div className='survey-top-title'>
                <p>List of product surveys they can take:</p>
                <span>Here you can find all of the Products that we have available to fill in a survey for. 
                First choose the Product Category, then you can see the Products and click the corresponding Take Survey button.</span>
            </div>
            <div style={{width:300}} data-testid='admin-platform-select'>
                <p style={{marginBottom:'5px', fontWeight:600}}>Product Category</p>
                <Select
                    options={surveyTemplate}
                    onChange={val => handleTemplateChange(val?.id)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    aria-label={'select-platform'}
                />

            </div>
            {!loading ?  <DataTable
                {...{
                    tableData:surveyProducts.data,
                    columnConfig:colConfig, 
                    disableDownload:true, 
                    disableFilter:true,
                    themeColor:'#5856d6',
                    disableSearch:true
                }}
            /> : <LoadingAnimation/>}
            {!surveyProducts.status && !surveyTemplate && <p>No surveys found for this template!</p>}
            {survey.isOpen &&
                <span title='Take Survey'>
                    <Survey
                        baseUrl={getBaseUrl()}
                        productId={survey.productId}
                        wwid={wwid}
                        productName={survey.productName}
                        surveyTitle={`${survey.productName} Survey`}
                        showIcon={false}
                        isOpen={true}
                        hideControls={true}
                        onClose={onClose}
                        onSuccess={onSuccess}
                    />
                </span>
            }
        </div>
    );
};

ActiveSurveys.propTypes  = {
    wwid:PropTypes.string.isRequired
};

export default ActiveSurveys;