import React from 'react';
import { useParams } from 'react-router-dom';
import SurveyResponses from './SurveyResponses';
import './survey.css';
import ActiveSurveys from './ActiveSurveys';
import PropTypes from 'prop-types';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import { getBaseUrl } from '../../util/getBaseUrl';
import SurveyAdminTemplate from './SurveyAdmin/SurveyAdminTemplate';

const Surveys = ({userWwid, isAdmin}) => {
    const params = useParams();
    return (
        <div style={{padding:'2rem'}}>
            <div className='survey-section-wrapper'>
                <div className='survey-divider'>Survey Admin</div>
                <SurveyAdminTemplate wwid={userWwid} isAdmin={isAdmin}/>
            </div>
            <div style={{marginTop:'2rem', }} className='survey-section-wrapper'>
                <div className='survey-divider'>Product Surveys</div>
                <div className='survey-page'>
                    <ActiveSurveys wwid={userWwid}/>
                    <SurveyResponses wwid={userWwid}/>
                    {params?.id && <Survey
                        baseUrl={getBaseUrl()}
                        productId={params.id}
                        wwid={userWwid}
                        showIcon={false}
                        isOpen={true}
                        hideControls={true}
                    />}
                </div>
                
            </div>
        </div>
    );
};

Surveys.propTypes = {
    userWwid:PropTypes.string.isRequired,
    isAdmin:PropTypes.bool.isRequired
};

export default Surveys;