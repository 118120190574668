import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './dashboardDetails.css';
import dashboardImage from '../../../assets/dashboards.webp';
import useDashboardStore from '../../../store/dashboard/useDashboardStore';
import DashboardSideMenu from './DashboardSideMenu';
import DashboardTableau from './DashboardTableau';
import DashboardContent from './DashboardContent';




// Dashboard details page - loading the required Tableau dashboad
// eslint-disable-next-line react/prop-types
const DashboardDetails = ({ setTitle, userId }) => {
    const navigate = useNavigate();
    const { productId, workbookId, workbookViewId } = useParams();
    const [respData, setRespData] = useState({});
    const { dashboardViews } = useDashboardStore();
    const [sideMenuData, setSideMenuData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const refObj = {};
    // Tracking active workbook
    const [value, setValue] = useState(+workbookId || 0);

    //Getting all three params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mylink = queryParams.get('mylink');

    // Default the urlFilters to null if there are no keys in the object.
    const urlFilters = [...queryParams].length ? Object.fromEntries(queryParams.entries()) : null;

    const handleIframeChange = pathObj => {
        navigate(`/dashboards/${pathObj?.dashboard_path}`);
    };
    
    // This function handles the change in the side tab by setting the new value and updating the iframe
    const handleSideTabChange = newValue => {
        // Set the new value for the side tab
        setValue(newValue);

        // Filter the side menu data to get the data related to the new value (workbook_id)
        const filterData = sideMenuData.filter(d => d.workbook_id === newValue);

        // If there is a matching data item, update the iframe with the new data
        if (filterData && filterData[0]) {
            handleIframeChange(filterData[0]);
        }
    };

    // This effect runs when the workbookId changes
    useEffect(() => {
        // Filter the side menu data to get the data related to the current workbookId
        const filterData = sideMenuData.filter(d => d.workbook_id === workbookId);

        // If there is a matching data item, update the iframe with the new data
        if (filterData && filterData[0]) {
            handleIframeChange(filterData[0]);
        }
    }, [workbookId]);

    // Event handlers (optional)
    const handleTabSwitch = event => {
        refObj.url = event.srcElement._vizImpl._src.split('&')[0];
        refObj.sheetName = event.detail._newSheetName;
        refObj.getDashboardPath();
    };
    
    
    return <div className="container" style={{ margin: '0px', padding: '0px', width: '100%', minheight: '600px !impoa', overflowY: 'auto' }}>
        <img src={dashboardImage} style={{ height: '8vh', width: '100%' }} />
        <div className="container-dashboard">
            <DashboardContent 
                setRespData={setRespData} 
                setSideMenuData={setSideMenuData} 
                setTitle={setTitle} 
                userId={userId} 
                refObj={refObj} 
                isLoaded={isLoaded} 
                setIsLoaded={setIsLoaded}
            />
            <hr />
            {isLoaded && <div className="content">
                <DashboardSideMenu handleSideTabChange={handleSideTabChange} workbookId={value} sideMenuData={sideMenuData} />
                <DashboardTableau 
                    handleTabSwitch={handleTabSwitch} 
                    mylink={mylink}
                    urlFilters={urlFilters}
                    sideMenuData={sideMenuData} 
                    dashboardViews={dashboardViews} 
                    productId={productId} 
                    workbookId={workbookId} 
                    workbookViewId={workbookViewId} 
                    respData={respData}
                    refObj={refObj}
                />
            </div>}
        </div>

    </div>;
};

export default DashboardDetails;