import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { HiMiniHome } from 'react-icons/hi2';
import { Survey } from '@jauj/reactpkg-tsis-survey';
import './survey.css';
import { getDetailErrorMsg } from '../../util/functions';
import { getRequest } from '../../services/axiosClient';
import { getBaseUrl } from '../../util/getBaseUrl';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import SurveyResponseTable from './SurveyResponseTable';
import useMainStore from '../../store/useMainStore';

const responseUrl = `${getBaseUrl()}/api/datasrcs/1/folder/survey/customQuery/get_product_survey_responses?filters=`;
const questionUrl = `${getBaseUrl()}/api/datasrcs/1/schemas/survey/tables/question/records?display-value=true&survey_template_id=`;
const responseSingleUrl = `${getBaseUrl()}/api/datasrcs/1/folder/survey/customQuery/get_survey_response_by_instance_id?filters=`;

const SurveyResponseViewer = ({userWwid, isAdmin}) => { 
    const {surveyTemplateAccessList, getSurveyTemplateAccessList} = useMainStore();
    const [responseData, setResponseData] = useState([]);
    const [originalRespData, setOriginalRespData]=  useState([]);
    const [questionData, setQuestiondata] = useState([]);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const [viewResponse, setViewResponse] = useState({ isOpen: false, questions: {} });

    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const loadData = async selectData => {
            setLoading(true);
            try { 

                if(id) { 
                    const responseRes = await getRequest(responseSingleUrl + id);
                    const data = responseRes.data.data[0];
                    const temp = selectData.find(e => e.survey_template_id === data.survey_template_id);
                    const prod = temp.products.find(e => e.product_id === data.product_id);

                    setViewResponse({ isOpen: true, questions: data });
                    if(!selectedTemplate || temp.survey_template_id !== selectedTemplate.survey_template_id) {
                        setSelectedTemplate(temp);
                    }
                    if(!selectedProduct || prod.product_id !== selectedProduct.product_id) { 
                        setSelectedProduct(prod);
                    }
                }
            } catch (e) { 
                toast.error(`Error loading data: ${getDetailErrorMsg(e)}`);
            } finally { 
                setLoading(false);
            }
        };
        if(!surveyTemplateAccessList.fetched){
            getSurveyTemplateAccessList(userWwid, isAdmin);
        }

        if(surveyTemplateAccessList.fetched){
            loadData(surveyTemplateAccessList.data);
        }
        
    }, [id, surveyTemplateAccessList.fetched]);

    useEffect(() => { 
        const loadData = async () => {
            setTableLoading(true);
            try { 
                const res = await getRequest(questionUrl + selectedTemplate.survey_template_id);
                const res1 = await getRequest(responseUrl + selectedTemplate.survey_template_id);
                setQuestiondata(res.data.data);
                setResponseData(res1.data.data); 
                setOriginalRespData(res1.data.data);
            } catch (e) {
                toast.error(`Error loading data: ${getDetailErrorMsg(e)}`);
            } finally{
                setTableLoading(false);
            }
        };
        setResponseData([]);
        if(selectedTemplate){
            loadData();
        }
        
    }, [selectedTemplate]);

    useEffect(() => {

        const newData = originalRespData.filter(item => {
            if(!selectedProduct?.product_id){
                return true;
            }
            return item.product_id === selectedProduct.product_id;
        });

        setResponseData(newData);
        
    }, [selectedProduct]);
    

    const handleQuestionSelect = question => {
        setSelectedQuestion(question);
        if (!question) {
            setResponseData(responseData.map(response => ({ ...response, answer: null })));
            return;
        }

        const newResponseData = responseData.map(response => {
            const questionResponse = response.responses.find(e => e.id === question.id);
            let answer;
            switch(questionResponse.question_type_name) {
            case 'Numeric Range':
                answer = questionResponse.range_value;
                break;
            case 'Yes / No':
                answer = questionResponse.boolean_value ? 'Yes' : 'No';
                break;
            case 'Free Text':
                answer = questionResponse.text_value;
                break;
            case 'Single Select': 
                answer = questionResponse.select_value.map(v => v.question_answer_name).join(', ');
                break;
            case 'Multi Select': 
                answer = questionResponse.select_value.map(v => v.question_answer_name).join(', ');
                break;
            default:
                answer = '';
            }
            
            return { ...response, answer: answer };
        });
        setResponseData(newResponseData);
    };


    const handleViewResponse = data => setViewResponse({ isOpen: true, questions: data });
    const handleCloseResponse = () => {
        setViewResponse({ isOpen: false, questions: {} });
        if (id) {
            const pathWithoutId = window.location.pathname.replace(`/survey-responses/${id}`, '/survey-responses');
            navigate(pathWithoutId);
        }
    };

    if(loading) return <LoadingAnimation/>;
    return ( 
        <div className='survey-container'>
            <ToastContainer theme='light' position='bottom-right' />
            <Link className='btn btn-blue' to='/survey' style={{width:190, marginBottom:'1rem'}}> <HiMiniHome size={22}/>Survey Home Page</Link>
            <div className='survey-top-title survey-divider' style={{minHeight:'30px', fontSize:'1em'}}>
                    Here you can view a list of all survey responses
            </div>
            <div className='survey-select-container'>
                <div className='survey-select-top-row'>
                    <div className='survey-select-div'>
                        <p>Survey Template</p>
                        <Select
                            options={surveyTemplateAccessList.data}
                            value={selectedTemplate ?? null}
                            onChange={opt => {setSelectedTemplate(opt); setSelectedProduct(null); setSelectedQuestion(null);}}
                            getOptionLabel={opt => opt.survey_template_name}
                            getOptionValue={opt => opt.survey_template_id}
                            aria-label='select-template'
                            isLoading={!surveyTemplateAccessList.fetched}
                        />
                    </div>
                    <div className='survey-select-div'>
                        <p>Product</p>
                        <Select
                            options={selectedTemplate?.products ? [{id:null, product_name:'--Select--'}, ...selectedTemplate.products] : null}
                            value={selectedProduct ?? null}
                            onChange={opt => {setSelectedProduct(opt); setSelectedQuestion(null);}}
                            getOptionLabel={opt => opt.product_name}
                            getOptionValue={opt => opt.product_id}
                            aria-label='select-product'
                            isDisabled={!selectedTemplate || !questionData || tableLoading}
                        />
                    </div>
                </div>
                <div className='survey-select-bottom-row'>
                    <div className='survey-select-div'>
                        <p>Question</p>
                        <Select
                            options={questionData.length > 0 ? questionData : null}
                            value={selectedQuestion ?? null}
                            onChange={opt => handleQuestionSelect(opt)}
                            getOptionLabel={opt => opt.question_text}
                            getOptionValue={opt => opt.id}
                            aria-label='select-question'
                            isDisabled={questionData.length < 1 || responseData.length < 1}
                            isClearable={true}
                        />
                    </div>
                </div>
            </div>
            { tableLoading 
                ? <LoadingAnimation/>
                : <SurveyResponseTable responseData={responseData} selectedQuestion={selectedQuestion} checkResponse={handleViewResponse}/> }
            { viewResponse.isOpen && <div>
                <span title='View Survey'>
                    <Survey
                        baseUrl={getBaseUrl()}
                        productId={viewResponse.questions.product_id}
                        wwid={viewResponse.questions.wwid}
                        productName={viewResponse.questions.product_name}
                        surveyTitle={`${viewResponse.questions.product_name} Survey`}
                        surveySubTitle={`${viewResponse.questions.respondent_name} - ${viewResponse.questions.response_date}`}
                        showIcon={false}
                        isOpen={viewResponse.isOpen}
                        hideControls={true}
                        showResponses={viewResponse.questions.responses}
                        onClose={handleCloseResponse}
                    />
                </span>
            </div> }
            
        </div>
    );
};

SurveyResponseViewer.propTypes = { 
    userWwid: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired
};

export default SurveyResponseViewer;