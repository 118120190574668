import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useMainStore from '../../../store/useMainStore';
import PropTypes from 'prop-types';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';

const SurveyAdmin = ({ wwid, isAdmin }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { surveyTemplateAccessList, getSurveyTemplateAccessList } = useMainStore();

    useEffect(() => {
        if (!params.id) {
            navigate('/survey-admin-form/new');
        } else if (!surveyTemplateAccessList.fetched && !isAdmin && !surveyTemplateAccessList.loading) {
            getSurveyTemplateAccessList(wwid, isAdmin);
        }
    }, [params.id, surveyTemplateAccessList.fetched, isAdmin, wwid, surveyTemplateAccessList.loading]);

    if (!isAdmin && (surveyTemplateAccessList.loading || !surveyTemplateAccessList.fetched)) {
        return <LoadingAnimation />;
    }
    

    const hasAccess =
        params.id === 'new' ||
        surveyTemplateAccessList?.data?.some(
            item => String(item.survey_template_id) === params.id
        );

    return (
        <>
            {isAdmin || hasAccess ? (
                <Outlet />
            ) : (
                <div className="access-denied">
                    <h2>Access Denied!</h2>
                    <p>You do not have permission to view this survey template.</p>
                    <button onClick={() => navigate('/')} className='btn btn-blue'>Go to Home</button>
                </div>
            )}
        </>
    );
};

SurveyAdmin.propTypes = {
    wwid: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default SurveyAdmin;
