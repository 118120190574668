import React, {useEffect, useState} from 'react';
import { getBaseUrl } from '../../../util/getBaseUrl';
import PropTypes from 'prop-types';
import AsyncSelectComponent from '../../../components/common/AsyncSelectComponent';
import TextInput from '../FormComponents/TextInput';
import CheckboxInput from '../FormComponents/CheckboxInput';
import { getRequest, postRequest, putRequest } from '../../../services/axiosClient';
import { toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../../util/functions';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import useMainStore from '../../../store/useMainStore';

const urlWwid = getBaseUrl() + '/api/datasrcs/1/schemas/rmr/tables/rmr_resource/records?fields-all=display_name,username,wwid,employee_status&record-limit=10&search-fields=employee_status&search-term=active&search-fields=display_name,username,wwid&search-term=';
const url = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/survey_template/records';
const urlAdminLink = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/survey_template_admin/';

const isDisabled = (saving, isEdited) => saving || (!isEdited.form && !isEdited.admin);

const SurveyAdminFormHeader = ({templateData, wwid, isAdmin}) => {
    const [template, setTemplate] = useState(templateData.data);
    const [admins, setAdmins] = useState({fetched:false, data:[]});
    const [selectedWwid, setSelectedWwid] = useState({});
    const [isEdited, setIsEdited] = useState({admin:false, form:false});
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const {getSurveyTemplateAccessList, getSurveyTemplates} = useMainStore();

    const getTemplateAdmins = async id => {
        setAdmins({fetched:false, data:[]});
        try {
            const resTemplate = await getRequest(urlAdminLink+ 'records?display-value=true&survey_template_id=' + id);
            const resWwid = resTemplate.data.data.map(item => ({
                display_name: item.admin_wwid_dv,
                wwid: item.admin_wwid,
                id: item.id,
            }));
            setAdmins({fetched:true, data:resWwid});
            setSelectedWwid({});
        } catch (error) {
            setAdmins({fetched:true, data:[]});
            toast.error('Error with getting survey template: ' + getDetailErrorMsg(error));
        } 
    };
    useEffect(()=>{
        if(templateData?.data?.id){
            getTemplateAdmins(templateData.data.id);
        } else{
            setAdmins({fetched:true, data:[]});
        }
        
    },[]);

    const handleSubmit = async e => {
        e.preventDefault();
        if (!e.target.form.checkValidity()) {
            e.target.form.reportValidity();
            return;
        }

        if(!isEdited.form && !isEdited.admin)
            return;
        
        const operation = template.id ? 'Updating' : 'Creating';
        const toastId = toast.loading(`${operation} Template`);
        setSaving(true);
        
        try {
            let response;
            if(isEdited.form){
                if (template.id) {  
                    response = await putRequest(`${url}/${template.id}`, template);
                } else {
                    response = await postRequest(url, template);
                }
                if (response) {
                    const record = response.data.data[0];
                    const newData = {...template, id: record.id};
                    setTemplate(newData);
                }
            }
            
            if(selectedWwid.addStatus){
                await postRequest(urlAdminLink + 'upload', selectedWwid.added.map(item => {
                    return {
                        admin_wwid:item.wwid,
                        survey_template_id:template.id ? template.id : response.data.data[0].id
                    };
                }));
            }
            if(selectedWwid.deleteStatus){
                await postRequest(urlAdminLink + 'uploadDelete', selectedWwid.deleted);
            }
            if(isEdited.admin){
                getTemplateAdmins(template.id ? template.id : response.data.data[0].id);
            }
            getSurveyTemplateAccessList(wwid, isAdmin);
            getSurveyTemplates();
            setIsEdited({form:false, admin:false});
            toast.update(toastId, {
                render: `${!template.id ? 'Created' : 'Updated' } Successfully!`,
                type: 'success',  isLoading: false, autoClose: 6000, closeButton: true
            });
            if(!templateData.data.id){
                navigate('/survey-admin-form/' + response.data.data[0].id);
            }
        } catch (error) {
            toast.update(toastId, {
                render: `Error with question update: ${getDetailErrorMsg(error)}`,
                type: 'error', isLoading: false, autoClose: 6000, closeButton: true
            }); 
        } finally{
            setSaving(false);
        }
    };

    const handleOptionChange = (newSelection, action ) => {
        if(action.name === 'owner'){
            setTemplate(prev => ({...prev, owner_wwid:newSelection.wwid}));
            setIsEdited(prev => ({...prev, form:true}));
        }else {
            const defaultWwids = new Set(admins.data.map(item => item.wwid));
            const newWwids = new Set(newSelection.map(item => item.wwid));
            const deletedWwids = [...defaultWwids].filter(wwid => !newWwids.has(wwid));
            const deleted = admins.data
                .filter(item => deletedWwids.includes(item.wwid))
                .map(item => item.id);
            const added = newSelection.filter(item => !admins.data.some(d => d.wwid === item.wwid));
            setSelectedWwid({ added:added, addStatus:added.length > 0 ? true : false, deleted:deleted, deleteStatus:deleted.length > 0 ? true : false });
            setIsEdited(prev => ({...prev, admin:true}));
        }
    };

    const handleChange = (value, field) => {
        setTemplate(prev => ({...prev, [field]:value}));
        setIsEdited(prev => ({...prev, form:true}));
    };
    return (
        <div>
            <h3 style={{marginBottom:10}}>Survey Template</h3>
            <form onSubmit={handleSubmit} style={{marginBottom:'2rem'}} >
                <div className='admin-form'>
                    <div className='admin-form-container' style={{width: '100%', maxWidth: '500px'}}>
                        <TextInput
                            label="Name"
                            name="name"
                            value={template.name || ''}
                            onChange={handleChange}
                            placeholder="Name"
                            required
                            type='text'
                        />
                        <CheckboxInput  
                            label="Active Flag"
                            name="active_flag"
                            checked={template.active_flag || false}
                            onChange={handleChange}
                        />
                    </div> 
                    <div className='admin-form-select-cont'>
                        <label className='admin-template-select' data-testid='admin-template-select' style={{width:300}}>
                            <p  className='custom-form-label'>Owner <span style={{color:'red'}}>*</span></p>
                            <AsyncSelectComponent 
                                urlReq={urlWwid} 
                                defaultKeyNames={{label:'display_name', value:'wwid'}} 
                                name='owner'  
                                isMulti={false}  
                                defValue={[templateData.defOwner]} 
                                handleChange={handleOptionChange}
                            />
                        </label>
                        <label className='admin-template-select' data-testid='admin-template-select' style={{width:300}}>
                            <p className='custom-form-label'>Admin</p>
                            {admins.fetched ? <AsyncSelectComponent 
                                urlReq={urlWwid} 
                                defaultKeyNames={{label:'display_name', value:'wwid'}} 
                                name='admin'  
                                isMulti={true}  
                                defValue={admins.data} 
                                handleChange={handleOptionChange} 
                            />: <div className='rf-select-placeholder-div'> <LoadingAnimation type={true}/></div>}
                        </label>
                    </div>

                                
                </div>
                
                <button className='btn btn-green' onClick={handleSubmit} disabled={isDisabled(saving, isEdited)}>{saving ? <LoadingAnimation type={true}/> : 'Save'}</button>
            </form>
        </div>
    );
};

SurveyAdminFormHeader.propTypes ={
    templateData:PropTypes.object.isRequired,
    wwid: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default SurveyAdminFormHeader;