import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import useMainStore from '../../../../store/useMainStore';
import LoadingAnimation from '../../../../components/common/loadingAnimation/LoadingAnimation';
import FormSubmitButtons from '../../FormComponents/FormSubmitButtons';
import { getRequest, postRequest, putRequest } from '../../../../services/axiosClient';
import { getBaseUrl } from '../../../../util/getBaseUrl';
import AsyncSelectComponent from '../../../../components/common/AsyncSelectComponent';
import { toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../../../util/functions';
import CheckboxInput from '../../FormComponents/CheckboxInput';
import TextInput from '../../FormComponents/TextInput';

const wwidSearchUrl = getBaseUrl() + '/api/datasrcs/1/schemas/rmr/tables/rmr_resource/records?fields-all=display_name,username,wwid,employee_status,email&record-limit=10&search-fields=employee_status&search-term=active&search-fields=display_name,username,wwid&search-term=';
const productAdminUrl = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/product_admin/';
const productUrl = getBaseUrl() + '/api/datasrcs/1/schemas/survey/tables/product/records';


const ProductForm = ({product, closePopup, newRecord, updateTable}) => {
    const {getWorkbooks, workbooks} = useMainStore();
    const [originalData, setOriginalData] = useState(product);
    const [productData, setProductData] = useState(product);
    const [isEdited, setIsEdited] = useState({admin:false, form:false});
    const [saving, setSaving] = useState(false);
    const [productAdmin, setProductAdmin] = useState({fetched:false, data:[]});
    const [selectedWwid, setSelectedWwid] = useState({});

    const getProductAdmins = async id =>{
        setProductAdmin({fetched:false});
        try {
            const res  = await getRequest(productAdminUrl + 'records?display-value=true&product_id=' + id);
            const resAdmins = res.data.data.map(item=> {
                return {
                    display_name: item.admin_wwid_dv,
                    wwid:item.admin_wwid,
                    id:item.id
                };
            });
            setProductAdmin({fetched:true, data:resAdmins});
        } catch (error) {
            setProductAdmin({fetched:true, data:[]});
            toast.error('Error with getting product admins' + getDetailErrorMsg(error));
        }
    };
    const resetForm = () => {
        setOriginalData(newRecord);
        setProductData(newRecord);
        setProductAdmin({fetched:true, data:[]});
    };

    useEffect(()=>{
        if(!workbooks.fetched)
            getWorkbooks();
        if(!productAdmin.fetched && originalData.id){
            getProductAdmins(originalData.id);
        } else{
            setProductAdmin({fetched:true, data:[]});
        }
            
    },[]);

    const handleChange = (value, field)=>{
        setProductData(prev => ({...prev, [field]:value}));
        setIsEdited(prev => ({...prev, form:true}));
    };

    const handleSubmit = async (e, addNew, close) =>{
        e.preventDefault();
        if (!e.target.form.checkValidity()) {
            e.target.form.reportValidity();
            return;
        }
        
        
        const operation = originalData.id ? 'Updating' : 'Creating';
        const toastId = toast.loading(`${operation} Product`);
        setSaving(true);
      
    
        try {
            let response;
            if(isEdited.form){
                if (originalData.id) { 
                    response = await putRequest(`${productUrl}/${originalData.id}`, productData);
                } else {
                    response = await postRequest(productUrl, productData);
                }
            }
            
            if(selectedWwid.addStatus){
                await postRequest(productAdminUrl + 'upload', selectedWwid.added.map(item => {
                    return {
                        admin_wwid: item.wwid,
                        product_id: product.id ? product.id : response.data.data[0].id
                    };
                }));
            }
            if(selectedWwid.deleteStatus){
                await postRequest(productAdminUrl + 'uploadDelete', selectedWwid.deleted);
            }
            
    
            if (response) {
                const record = response.data.data[0];
                const newData = {
                    ...productData,
                    id: record.id,
                    
                };
                setOriginalData(newData);
                setProductData(newData);
            }
            setIsEdited(false);
            toast.update(toastId, {
                render: `${originalData.id ? 'Updated' : 'Created' } Successfully!`,
                type: 'success',  isLoading: false, autoClose: 6000, closeButton: true
            });
            setSelectedWwid({ addStatus:false, deleteStatus:false});
            if(!close && (selectedWwid.addStatus || selectedWwid.deleteStatus))
                getProductAdmins(product.id ? product.id : response?.data?.data[0]?.id );
            if(addNew)
                resetForm();
            updateTable();
            if(close){closePopup();}
            
        } catch (error) {
            toast.update(toastId, { 
                render: `Error with question update: ${getDetailErrorMsg(error)}`,
                type: 'error', isLoading: false, autoClose: 6000, closeButton: true
            }); 
        } finally{
            setSaving(false);
        }

    };

    const handleOptionChange = newSelection => {
        const defaultWwids = new Set(productAdmin.data.map(item => item.wwid));
        const newWwids = new Set(newSelection.map(item => item.wwid));
        const deletedWwids = [...defaultWwids].filter(wwid => !newWwids.has(wwid));
        const deleted = productAdmin.data
            .filter(item => deletedWwids.includes(item.wwid))
            .map(item => item.id);
        const added = newSelection.filter(item => !productAdmin.data.some(d => d.wwid === item.wwid));
        setSelectedWwid({ added, addStatus:added.length > 0, deleted, deleteStatus:deleted.length > 0});
        setIsEdited(prev => ({...prev, admin:true}));
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className='survey-admin-form-wrap'>
                <div className='admin-form-container' style={{width: '100%', }}>
                    <TextInput
                        label="Name"
                        name="name"
                        value={productData?.name || ''}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                        type='text'
                    />
                    <CheckboxInput  
                        label="Active Flag"
                        name="active_flag"
                        checked={productData.active_flag || false}
                        onChange={handleChange}
                    />
                </div> 
                <TextInput
                    label="Email Notification"
                    name="email_notif"
                    value={productData?.email_notif || ''}
                    onChange={handleChange}
                    placeholder="Email"
                    type='textarea'
                />
                <div className='admin-form-select-cont'>
                    
                    <div style={{width:300}} data-testid='admin-template-select'>
                        <p style={{marginBottom:'5px'}} className='custom-form-label' >Workbook</p>
                        {workbooks.fetched && !saving ? <Select
                            options={[{id:null, name:'--Select--'}, ...workbooks.data]}
                            onChange={val => handleChange(val?.id, 'workbook_id')}
                            getOptionLabel={option => option.id ? `${option.id} - ${option.name}` : option.name}
                            getOptionValue={option => option.id}
                            aria-label={'select-product'}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            defaultValue={workbooks.data.filter(item=> item.id === productData.workbook_id)}
                            isClearable
                        />: <div className='rf-select-placeholder-div'> <LoadingAnimation type={true}/></div>}
                    </div>
                    <label className='admin-template-select' data-testid='admin-template-select' style={{width:300}}>
                        <p className='custom-form-label'>Admin</p>
                        {productAdmin.fetched && !saving ? <AsyncSelectComponent 
                            urlReq={wwidSearchUrl} 
                            defaultKeyNames={{label:'display_name', value:'wwid'}} 
                            name='admin'  
                            isMulti={true}  
                            defValue={productAdmin.data} 
                            handleChange={handleOptionChange}
                            
                        />: <div className='rf-select-placeholder-div'> <LoadingAnimation type={true}/></div>}
                    </label>
                </div>
                <FormSubmitButtons   isSaveDisabled={() => (!isEdited.admin && !isEdited.form)} handleSubmit={handleSubmit} saving={saving}/>
            </form>
        </div>
    );
};

ProductForm.propTypes = {
    product: PropTypes.object.isRequired,
    closePopup:PropTypes.func.isRequired,
    newRecord:PropTypes.object.isRequired,
    updateTable:PropTypes.func.isRequired
};

export default ProductForm;