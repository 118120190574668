import React from 'react';
import { FaEdit } from 'react-icons/fa';

export const generateColumns = (type, handleEdit) => {
    let columns = [
        {
            accessorKey: 'id', 
            header: 'Id',
            textAlign: 'center',
            dataType: 'number',
            filterFn: 'numberFilter',
            disableFilter: true,
        },
        {
            accessorKey: `${type === 'products' ? 'name' : 'question_text'}`, 
            header: type === 'products' ? 'Product Name' : 'Question Text',
            textAlign: 'center',
            dataType: type === 'products' ? 'number' : 'string',
            filterFn: type === 'products' ? 'numberFilter' : 'stringFilter',
            disableFilter: true,
        },
        {
            accessorKey: type === 'products' ? 'workbook_id_dv' : 'question_type_id_dv', 
            header: type === 'products' ? 'Workbook' : 'Question Type',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true,
        },
        {
            accessorKey: type === 'products' ? 'email_notif' : 'mandatory_flag', 
            header: type === 'products' ? 'Email Notification' : 'Mandatory Flag',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true,
        },
        {
            accessorKey: 'active_flag',
            header: 'Status',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true,
            cell: info => (
                <>{info.row.original.active_flag ? <span className='info-active'>Active</span> : <span className='info-inactive'> Inactive</span>}</>
            )
        },
        {
            header: 'Action',
            textAlign: 'center',
            minWidth: 70,
            cell: info => (
                <div className='admin-form-icon-wrap'>
                    <span className='admin-category-edit' data-testid='admin-platform-edit' onClick={() => handleEdit(info.row.original, type === 'questions')}><FaEdit size={22}/></span>
                </div>
            )
        }
    ];

    if (type === 'questions') {
        columns.splice(4, 0, {
            accessorKey: 'question_order', 
            header: 'Order',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true,
        });
    }

    return columns;
};