import React from 'react';
import { FcFolder } from 'react-icons/fc'; 
import { MdExpandMore, MdChevronRight } from 'react-icons/md'; 
import PropTypes from 'prop-types';

const  FileSidebar = ({ folders, handlePathChange, toggleFolder, expandedPaths }) => {
    const renderFolders = (folderPath, depth = 0) => {
        return folderPath.map(folder => {
            const isExpanded = expandedPaths.has(folder.path.join('/'));
            const hasChildren = folder.children && folder.children.length > 0;
            const Icon = isExpanded ? MdExpandMore : MdChevronRight; 
            const padPixel = 7;
            return (
                <div key={folder.path.join('/')} style={{ paddingLeft: `${padPixel * depth}px` }}>
                    <div 
                        className={'sidebar-folder'}
                        onClick={e => { e.stopPropagation(); handlePathChange(folder.path); }}
                        data-testid='handlePathChange'
                    >
                        {hasChildren ? (
                            <Icon 
                                size={20} 
                                style={{ marginRight: '5px' }} 
                                onClick={e => { e.stopPropagation(); toggleFolder(folder.path); }} 
                                data-testid='toggleFolder'
                            />
                        ) : (<span style={{width:'25px'}}></span>)}
                        <FcFolder size={24} style={{ marginRight: '10px' }} />
                        <span style={{overflow:'hidden'}}>
                            {folder.name}
                        </span>
                    </div>
                    {isExpanded && hasChildren && renderFolders(folder.children, depth + 1)}
                </div>
            );
        });
    };

    return (
        <div className="sidebar">
            {renderFolders(folders)}
        </div>
    );
};

FileSidebar.propTypes = {
    folders:PropTypes.array, 
    handlePathChange:PropTypes.func, 
    toggleFolder:PropTypes.func, 
    expandedPaths:PropTypes.object
};

export default FileSidebar;