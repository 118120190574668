import React from 'react';
import PropTypes from 'prop-types';

const CheckboxInput = ({
    label,
    name,
    checked,
    onChange,
    ...rest
}) => (
    <label className='custom-form-input-wrap' htmlFor={name}  style={{minWidth:130, alignItems:'center'}}>

        <span className='custom-form-label'>{label}</span>
        <input 
            type="checkbox"
            name={name}
            id={name}
            checked={checked} 
            onChange={e => onChange(e.target.checked, name)} 
            className='custom-form-checkbox'
            {...rest}
        />

    </label>
);

CheckboxInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CheckboxInput;
