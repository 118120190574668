

export const commonColumns = () => [
    {
        header: 'Date Time',
        accessorKey: 'datetime',
        textAlign: 'left',
        minWidth: 5,
        disableFilter: true,
    },
    {
        header: 'Level',
        accessorKey: 'level',
        textAlign: 'left',
        minWidth: 5,
        disableFilter: true,
    },
    {
        header: 'Message',
        accessorKey: 'msg',
        textAlign: 'left',
        minWidth: 5,
        disableFilter: true,
    },
];
