import React,{useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { debounce, getDetailErrorMsg } from '../../util/functions';
import { getRequest } from '../../services/axiosClient';


const AsyncSelectComponent = props => {
    
    const loadOptionData = async (inputText, callback) => {
        try {
            const json = await getRequest(props.urlReq + inputText);
            let result;
            if(props.urlReq.includes('employee_status')){
                result = json.data.data.filter(record => record.employee_status === 'ACTIVE');
            } else {
                result = json.data.data;
            }
            callback(result);
        } catch (err) {
            const msg = getDetailErrorMsg(err);
            toast.error('Error with getting employee info, please refresh page or try again later' + msg);

        }
        
    };
    const delayTime = 400;
    const loadOptionDataDebounced = useCallback(
        debounce(loadOptionData, delayTime),[]);
    
    //getting option label
    const optionLabel = option=> props.hideId ? `${option[props.defaultKeyNames.label]}` :  `${option[props.defaultKeyNames.value]} - ${option[props.defaultKeyNames.label]}`;
   
    if(props.isMulti){
        return (

            <>
                <AsyncSelect
                    onChange={(value, action )=> props.handleChange(value, action)}
                    loadOptions={loadOptionDataDebounced}      
                    getOptionLabel={option =>  optionLabel(option)}
                    getOptionValue={option => option[props.defaultKeyNames.value]} 
                    name={props.name}
                    isClearable={false}
                    isMulti
                    backspaceRemovesValue={false}
                    defaultValue={props.defValue || ''}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    placeholder={props.placeholder || 'Select...'}
                    minWidth='300px'
                    aria-label={'select-async-multi'}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </>
        );
    }
 
    return (<>
        <AsyncSelect
            loadOptions={loadOptionDataDebounced}      
            onChange={(value, action )=> props.handleChange(value, action)}
            getOptionLabel={option =>  optionLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            name={props.name}
            defaultValue={props.defValue || ''}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder={props.placeholder || 'Select...'}
            aria-label={'select-async-single'}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            minWidth='300px'
        />
    </>
         
    );
};

AsyncSelectComponent.propTypes = {
    urlReq: PropTypes.string.isRequired,
    defValue: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
    defaultKeyNames: PropTypes.object.isRequired,
    name:PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    hideId: PropTypes.bool,
    value:PropTypes.any,
    isClearable:PropTypes.bool,
    placeholder:PropTypes.string,
};

export default AsyncSelectComponent;