import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';
import { MdPreview } from 'react-icons/md';
import './survey.css';


const SurveyResponseTable = ({responseData, selectedQuestion=null, checkResponse}) => {
    // Concatenate the survey answers so they can be used when searching.
    responseData.forEach(response => { 
        response.all_answers = response.responses
            .map(r => r.answer)
            .join(' ').toLowerCase();
    });

    const colConfig = [
        {
            accessorKey: 'response_instance_id', 
            header: 'Id',
            textAlign: 'center',
            dataType: 'number',
            filterFn: 'numberFilter',
            disableFilter: true,
        },
        {
            accessorKey: 'respondent_name',
            header: 'Respondent', 
            textAlign: 'left',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: false,
        },
        {
            accessorKey:'response_date', 
            header: 'Survey Date',  
            textAlign: 'left',
            dataType: 'date',
            filterFn: 'dateFilter',
            disableFilter: false,
        },
        ...(selectedQuestion ? [{
            accessorKey: 'answer',
            header: 'Answer', 
            textAlign: 'left',
            dataType: 'string', 
            filterFn: 'stringFilter',
            disableFilter: true,
        }] : []),
        {
            accessorKey: 'all_answers',
            header: 'Response',
            textAlign: 'center',
            disableFilter: true,
            minWidth: 70,
            cell: info => (
                <div >
                    <span  onClick={()=> checkResponse(info.row.original)} className='survey-link'> <MdPreview size={25}/> View Response</span>
                </div>
            )
        }
    ];

    return (
        <div data-testid='survey-response-table'>
            <div style={{marginTop: '10px'}}>
                {
                    responseData.length > 0
                        ? <DataTable
                            {...{
                                tableData: responseData,
                                columnConfig: colConfig,
                                disableDownload: true,
                                disableFilter: false,
                                themeColor: '#5856d6'
                            }}
                        />
                        : <p  style={{fontSize:'1.5em', textAlign:'center', marginTop:'4rem'}}>There are no responses for the current selection.</p>
                }
            </div>
        </div>
    );
    

};

SurveyResponseTable.propTypes = { 
    responseData: PropTypes.array.isRequired,
    selectedQuestion: PropTypes.object,
    checkResponse: PropTypes.func.isRequired
};

export default SurveyResponseTable;